<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="视频号" prop="appid" label-width="100">
                <el-select v-model="queryParams.appid" size="mini">
                    <el-option v-for="item in appList" :key="item.appid" :value="item.appid"
                               :label="item.appName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="productNames" label-width="100">
                <el-input v-model="queryParams.productNames" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="流水号" prop="transactionId" label-width="100">
                <el-input v-model="queryParams.transactionId" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="订单状态" prop="status" label-width="100">
                <el-select v-model="queryParams.status" size="mini">
                    <el-option v-for="item in orderStatusList" :key="item.status" :value="item.status"
                               :label="item.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="同步状态" prop="syncStatus" label-width="100">
                <el-select v-model="queryParams.syncStatus" size="mini">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="未同步" :value="0"></el-option>
                    <el-option label="同步完成" :value="9"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单创建时间" prop="createTimeRange" label-width="100">
                <el-date-picker
                        size="mini"
                        v-model="createTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="订单更新时间" prop="updateTimeRange" label-width="100">
                <el-date-picker
                        size="mini"
                        v-model="updateTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="listPage">搜索
                </el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="primary"
                        plain
                        icon="el-icon-refresh"
                        size="mini"
                        @click="syncOrder = true"
                >同步
                </el-button>
            </el-col>
        </el-row>

        <el-table v-loading="loading" :data="orderList">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="订单号" align="center" prop="orderId"/>
            <el-table-column label="商品名称" align="center" prop="productNames">

            </el-table-column>
            <el-table-column label="流水单号" align="center" prop="transactionId">
            </el-table-column>
            <el-table-column label="订单金额" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.orderDetailJson.price_info">{{
                            formatMoney(scope.row.orderDetailJson.price_info.order_price)
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="实付金额" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.orderDetailJson.price_info">{{
                            formatMoney(scope.row.orderDetailJson.price_info.product_price)
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="支付时间" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.orderDetailJson.pay_info && scope.row.orderDetailJson.pay_info.pay_time">
                        {{
                        formatDate(new Date(scope.row.orderDetailJson.pay_info.pay_time * 1000), "yyyy-MM-dd hh:mm:ss")
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="快递单号" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.orderDetailJson.delivery_product_info">{{
                        scope.row.orderDetailJson.delivery_product_info.waybill_id
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="承运公司" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.orderDetailJson.delivery_product_info">{{
                        scope.row.orderDetailJson.delivery_product_info.delivery_name
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center" prop="status">
                <template v-slot="scope">
                    <el-tooltip v-for="item in orderStatusList" :key="item.status" class="item" effect="dark"
                                :content="item.remarks" placement="top-start">
                        <span v-if="scope.row.status === item.status">{{ item.name }}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="同步状态" align="center" prop="syncStatus">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.syncStatus === 0">未同步</el-tag>
                    <el-tag v-if="scope.row.syncStatus === 9" type="success">已同步</el-tag>
                    <el-tag v-if="scope.row.syncStatus === 1" type="danger">同步失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime"/>
            <el-table-column label="更新时间" align="center" prop="updateTime"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template v-slot="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                    >手动转化
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.current"
                :limit.sync="queryParams.size"
                @pagination="listPage"
        />

        <el-dialog title="视频号订单同步" :visible.sync="syncOrder" width="80%">
            <el-form :model="syncParams" ref="queryForm" :inline="true" label-width="68px">
                <el-form-item label="订单状态" prop="status" label-width="100">
                    <el-select v-model="syncParams.status" size="mini">
                        <el-option v-for="item in orderStatusList" :key="item.status" :value="item.status"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单创建时间" prop="createTimeRange" label-width="100">
                    <el-date-picker
                            size="mini"
                            v-model="syncCreateTimeRange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            :onPick="handlePick('订单创建时间',syncCreateTimeRange)"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="订单更新时间" prop="updateTimeRange" label-width="100">
                    <el-date-picker
                            size="mini"
                            v-model="syncUpdateTimeRange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            :onPick="handlePick('订单更新时间',syncUpdateTimeRange)"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="syncChannelsOrder">同步
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import * as channelsApi from "@/api/wework/wechat/wechatChannelsOrder";

export default {
    name: "WechatChannelsOrder",
    mounted() {
        this.findAppList()
        this.orderStatusOptions()
        this.listPage()
    },
    data() {
        return {
            total: 0,
            queryParams: {
                current: 1,
                size: 10
            },
            loading: false,
            orderList: [],
            createTimeRange: [],
            updateTimeRange: [],
            orderStatusList: [],
            syncOrder: false,
            syncCreateTimeRange: [],
            syncUpdateTimeRange: [],
            syncParams: {},
            appList: [],
        }
    },
    methods: {
        formatMoney(money) {
            if (money) {
                return (money / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            return '0.00'
        },
        formatDate(date, fmt) {
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
            }
            let o = {
                'M+': date.getMonth() + 1,
                'd+': date.getDate(),
                'h+': date.getHours(),
                'm+': date.getMinutes(),
                's+': date.getSeconds()
            }
            for (let k in o) {
                if (new RegExp(`(${k})`).test(fmt)) {
                    let str = o[k] + ''
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
                }
            }
            return fmt
        },
        /**
         * 时间选择时判断提醒
         * @param title
         * @param time
         */
        handlePick(title, time) {
            let end = new Date(time[1] + " 00:00:00")
            let start = new Date(time[0] + " 00:00:00")
            if ((end - start) > (24 * 60 * 60 * 1000 * 6)) {
                this.$message.error(title + "同步间隔最大支持7天")
            }
        },
        /**
         * 获取订单选项
         */
        orderStatusOptions() {
            channelsApi.orderStatus().then(res => {
                this.orderStatusList = res
            })
        },
        /**
         * 列表查询
         */
        listPage() {
            channelsApi.listPage(this.queryParams).then(res => {
                if (res.success) {
                    this.queryParams.current = res.data.current
                    this.queryParams.size = res.data.size
                    this.total = res.data.total
                    this.orderList = res.data.data
                    for (let o of this.orderList) {
                        o['orderDetailJson'] = JSON.parse(o['orderDetail'])
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 重置
         */
        resetQuery() {

        },
        findAppList() {
            this.loading = true;
            channelsApi.findAppList().then(res => {
                this.loading = false
                if (res.success) {
                    this.appList = res.data
                    this.queryParams.appid = this.appList[0].appid
                    this.syncParams.appid = this.appList[0].appid
                }
            })
        },
        /**
         * 同步订单
         */
        syncChannelsOrder() {
            if (this.syncCreateTimeRange) {
                this.syncParams['createStart'] = this.syncCreateTimeRange[0]
                this.syncParams['createEnd'] = this.syncCreateTimeRange[1]
            }
            if (this.syncUpdateTimeRange) {
                this.syncParams['updateStart'] = this.syncUpdateTimeRange[0]
                this.syncParams['updateEnd'] = this.syncUpdateTimeRange[1]
            }
            this.loading = true;
            channelsApi.syncChannelsOrder(this.syncParams).then(res => {
                this.loading = false;
                if (res.success) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    },
}
</script>

<style scoped>

</style>