import request from '@/utils/request.js';

/**
 * 分页查询
 * @param params
 */
export function listPage(params) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/wechat/channels/order/listPage`, method: 'get', params: params
    })
}

/**
 * 分页查询
 * @param params
 */
export function orderStatus() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/wechat/channels/order/orderStatus`, method: 'get'
    })
}

/**
 * 同步订单
 * @param params
 */
export function syncChannelsOrder(params) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/wechat/channels/order/syncChannelsOrder`,
        method: 'post',
        data: JSON.stringify(params)
    })
}

/**
 * 查询我的应用列表
 * @param params
 */
export function findAppList() {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/wechat/channels/order/findAppList`, method: 'get'
    })
}